import { getQueryString } from "@tawenda-npm/tawenda-utils";

export class FilterElements {
  wrapper: HTMLDivElement | null;
  items: Array<any>;
  filterValue: string;

  constructor(private input: HTMLInputElement) {
    this.wrapper = document.querySelector("[data-sort-wrapper]");
    this.items = Array.from(document.querySelectorAll("[data-sort-item]"));
  }

  public filter(): void {
    this.input.addEventListener("input", (e: Event) => {
      this.filterValue = this.input.value.toLowerCase();

      if (this.filterValue === "") {
        this.reset();
        return;
      }

      const re = new RegExp(this.filterValue, "gi");

      this.items
        .filter((el: HTMLElement) => {
          return !el.dataset.sortName.match(re);
        })
        .map((el, idx) => {
          FilterElements.hideElement(el);
        });
      this.items
        .filter((el: HTMLElement) => {
          return el.dataset.sortName.match(re);
        })
        .map(function (el, idx) {
          FilterElements.showElement(el);
        });
    });
  }

  private static showElement(element: HTMLElement): void {
    element.style.display = "inherit";
  }

  private static hideElement(element: HTMLElement): void {
    element.style.display = "none";
  }

  private reset(): void {
    this.items.map((el: HTMLElement) => {
      FilterElements.showElement(el);
    });
  }
}
