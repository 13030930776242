import { Page } from "./Page";
import initTopCategoriesCarousel from "../libs/topCategories";
import { SortElements } from "../libs/SortElements";
import { FilterElements } from "../libs/FilterElements";
import { DropDownHandler } from "../libs/DropDown";

export class AllCategoriesPage extends Page {
  public async start(): Promise<void> {
    await super.start();

    initTopCategoriesCarousel();

    const categoriesFilterDropDown = document.getElementById(
      "dropdown-categories-filter"
    );
    if (categoriesFilterDropDown) {
      new DropDownHandler(categoriesFilterDropDown, true, true);
    }

    new SortElements().sort();

    const filterInput: HTMLInputElement = document.querySelector(
      "[data-filter-input]"
    );
    if (filterInput) {
      new FilterElements(filterInput).filter();
    }
  }
}
