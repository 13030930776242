const searchHandler = () => {
  const form: HTMLFormElement = document.querySelector("[data-search]");
  if (form) {
    form.addEventListener("submit", (e: Event) => {
      e.preventDefault();
      const input: HTMLInputElement = form.querySelector("input[type=search]");
      if (input.value.trim().length === 0) {
        return;
      }
      document.location.href = input.dataset.searchUrl.replace(
        "QUERY",
        encodeURI(input.value)
      );
    });
  }
};

export default searchHandler;
