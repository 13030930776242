import { toggleClass, toggleText } from "@tawenda-npm/tawenda-utils";
import { tns } from "tiny-slider";
import { Page } from "./Page";
import initTopCategoriesCarousel from "../libs/topCategories";

export class VideosListPage extends Page {
  public async start(): Promise<any> {
    await super.start();
    initTopCategoriesCarousel();
  }

  private seoDescription(): void {
    document
      .querySelectorAll("[data-toggle-class]")
      .forEach((button: HTMLElement) => {
        button.addEventListener("click", () => {
          toggleClass(button);
        });
      });

    document
      .querySelectorAll("[data-toggle-text]")
      .forEach((element: HTMLElement) => {
        element.addEventListener("click", () => {
          toggleText(element);
        });
      });
  }
}
