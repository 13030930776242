import { tns } from "tiny-slider";

const initTopCategoriesCarousel = () => {
  const carouselTopCategories = document.getElementById(
    "top-categories-carousel"
  );

  if (carouselTopCategories) {
    tns({
      container: carouselTopCategories,
      items: 1,
      slideBy: 1,
      mouseDrag: true,
      center: true,
      gutter: 20,
      autoplayHoverPause: true,
      nav: false,
      controls: true,
      controlsContainer: ".carousel-content__navigation",
      responsive: {
        768: {
          items: 2,
        },

        1024: {
          items: 4,
        },
      },
    });
  }
};

export default initTopCategoriesCarousel;
