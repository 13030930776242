import Cookies from "js-cookie";

export class UserFavorites {
  public init(): void {
    document
      .querySelectorAll("[data-remove-this-video-from-favorites]")
      .forEach(async (el: HTMLElement) => {
        el.addEventListener("click", async (e: Event) => {
          e.preventDefault();

          const removeFavoriteUrl = el.dataset.favoriteChangeUrl;

          const response = await fetch(removeFavoriteUrl, {
            headers: { "X-CSRFToken": Cookies.get("csrftoken") || "" },
            method: "post",
            body: JSON.stringify({ mode: "remove" }),
          });
          if (response.ok) {
            const result = await response.json();
            if (result.success) {
              const elementToRemove = el.closest(
                el.dataset.parentSelectorToHideOnSuccess
              );
              elementToRemove?.remove();
            }
          }
        });
      });
  }
}
