import { getQueryString } from "@tawenda-npm/tawenda-utils";

export class SortElements {
  defaultSort = "name";
  sortBy: string;
  wrapper: HTMLDivElement | null;
  items: Array<any>;

  constructor() {
    this.sortBy = getQueryString("order") ?? this.defaultSort;
    this.wrapper = document.querySelector("[data-sort-wrapper]");
    this.items = Array.from(document.querySelectorAll("[data-sort-item]"));
  }

  public sort(): void {
    document
      .querySelectorAll("[data-sort-order]")
      .forEach((buttonElement: HTMLElement) => {
        buttonElement.addEventListener("click", () => {
          if (this.wrapper && this.sortBy) {
            const order: string | undefined = buttonElement.dataset.sortOrder;

            if (!order || order === this.sortBy) return;

            this.sortBy = order;
            this.sortElements();
            this.wrapper.innerHTML = this.getHTML();
          }
        });
      });
  }

  private sortElements(): void {
    this.items.sort((a: Element, b: Element) => {
      if (a && b) {
        const aSort = a.getAttribute(`data-sort-${this.sortBy}`);
        const bSort = b.getAttribute(`data-sort-${this.sortBy}`);

        let x: string | number | undefined = aSort?.toString().toLowerCase();
        let y: string | number | undefined = bSort?.toString().toLowerCase();

        if (x && y) {
          if (!isNaN(parseInt(x))) {
            x = parseInt(x);
            y = parseInt(y);
          }

          if (x < y) {
            return this.isReverse() ? 1 : -1;
          }
          if (x > y) {
            return this.isReverse() ? -1 : 1;
          }
        }
      }
      return 0;
    });
  }

  private isReverse(): boolean {
    return this.sortBy !== this.defaultSort;
  }

  public getHTML(): string {
    return Array.from(this.items).reduce((content: string, el: Element) => {
      return (content += el.outerHTML);
    }, "");
  }
}
