import { toggleClass } from "@tawenda-npm/tawenda-utils/lib/index";
import { switchClasses, toggleText } from "@tawenda-npm/tawenda-utils";
import Cookies from "js-cookie";

interface PlaylistInterface {
  id: number;
  name: string;
  video_in: boolean;
}

interface JsonToggleVideoInPlaylistInterface {
  success: boolean;
  message: string;
}

export class Playlist {
  protected readonly createButton: HTMLButtonElement;
  protected readonly form: HTMLFormElement;
  protected readonly listForAdd: HTMLElement;

  protected readonly getUrl: string;
  protected readonly toggleUrl: string;

  protected playlists: Array<PlaylistInterface> | undefined;

  constructor(protected wrapper: HTMLElement) {
    this.createButton = this.wrapper.querySelector("[data-create-button]");
    this.form = this.wrapper.querySelector("form");
    this.listForAdd = this.wrapper.querySelector("[data-playlist-add]");
    this.getUrl = this.wrapper.dataset.playlistStateUrl;
    this.toggleUrl = this.wrapper.dataset.playlistToggleUrl;
  }

  public async init(): Promise<void> {
    await this.loadPlaylists();
    toggleClass(this.listForAdd);
    this.initEvents();
  }

  private async loadPlaylists(): Promise<void> {
    const response = await fetch(this.getUrl);
    if (response.ok) {
      const result = await response.json();
      if (result.success) {
        this.playlists = result.video_playlists;
        if (this.playlists.length > 0) {
          this.setPlaylists();
        }
      }
    }
  }

  private setPlaylists(): void {
    let html = "";
    this.playlists.forEach((playlist: PlaylistInterface) => {
      html += `<a href data-add-to-playlist-id="${playlist.id}" data-video-in-playlist="${playlist.video_in}">
                <i class="fas fa-plus" data-switch-class-new="fa-check" data-switch-class-current="fa-plus"></i> ${playlist.name}
               </a>`;
    });
    this.listForAdd.innerHTML = html;

    this.listForAdd
      .querySelectorAll("[data-add-to-playlist-id]")
      .forEach((el: HTMLElement) => {
        const icon: HTMLElement = el.querySelector("i");
        if (el.dataset.videoInPlaylist === "true") {
          switchClasses(icon);
        }

        el.onclick = async (e: Event) => {
          e.preventDefault();

          const result = await this.toggleVideoInPlaylist(
            el.dataset.addToPlaylistId
          );
          if (result.success) {
            switchClasses(icon);
          }
        };
      });
  }

  private initEvents(): void {
    this.createButton.onclick = (e: Event) => {
      e.preventDefault();
      toggleClass(this.createButton);
      toggleText(this.createButton);
    };

    this.form.onsubmit = async (e: Event) => {
      e.preventDefault();
      const response = await fetch(this.form.action, {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || "",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "post",
        body: new FormData(this.form),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.success) {
          await this.loadPlaylists();
          toggleClass(this.createButton);
          toggleText(this.createButton);
        }
      }
    };
  }

  private async toggleVideoInPlaylist(
    playlistId: string | number
  ): Promise<JsonToggleVideoInPlaylistInterface> {
    const formData = new FormData();
    formData.append("playlist_pk", playlistId.toString());

    const response = await fetch(this.toggleUrl, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || "",
        "X-Requested-With": "XMLHttpRequest",
      },
      method: "post",
      body: formData,
    });

    return await response.json();
  }
}
